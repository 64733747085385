export const URLS = {
    main: process.env.REACT_APP_API_URL,
    billingMain: process.env.REACT_APP_BILLING_URL,
    billingMainV2: process.env.REACT_APP_BILLING_URL_V2,
    users: 'users',
    paymentInfo: `${process.env.REACT_APP_BILLING_URL}/payment/paymentInfo`,
    userBilling: `${process.env.REACT_APP_BILLING_URL_V2}/users/me/billingInfo`,
    purchasedPlans: `${process.env.REACT_APP_BILLING_URL_V2}/users/me/purchasedPlans`,
    customerPortal: `${process.env.REACT_APP_BILLING_URL_V2}/users/me/stripeCustomerPortal`,
    stripeCheckoutSession: `${process.env.REACT_APP_BILLING_URL_V2}/payment/stripeCheckoutSession`,
}