import React from 'react'
import { formatSum } from '../../../utilities/currency'

export const DesktopPricingPlan =  ({ isLast, label, pricing, pricingPeriod, perMonth, checkboxes, support, onClick, currencySymbol, selected }) => (
    <li className={`${!isLast ? 'border-r' : ''} divide-gray-200`}>
        <div className="px-6 align-top text-center">
            <p className="text-pink mb-2 font-medium">{label}</p>
            <p>
                <span className="text-2xl font-extrabold text-purple">{pricing ? formatSum(currencySymbol, pricing) : '\u00A0'}</span>
                <span className="text-base font-medium text-gray-400">{pricingPeriod}</span>
            </p>
            <div className="py-5 text-sm text-center">
                <span className="text-gray-400">Up to</span> {perMonth}
            </div>
            {Object.values(checkboxes).map((checkbox, i) => (
                <div className="py-5 text-center" key={i}>
                    {checkbox ? (
                        <svg className="h-5 w-5 text-pink inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd" />
                        </svg>
                    ) : (
                            <svg className="ml-auto h-5 w-5 text-gray-400 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            </svg>
                        )}
                </div>
            ))}
            <div className="pt-5 text-sm text-center">
                {support}
            </div>
        </div>
        <div className="pt-5 px-6">
            {!!pricing && (
                <button
                    onClick={onClick}
                    className='block w-full bg-pink rounded-md px-4 py-2 font-semibold text-white text-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-light disabled:opacity-50'
                    disabled={selected}>Subscribe</button>
            )}
        </div>
    </li>
)
