import {
    setUserType, 
    selectNewSubscriptionType,
    setExistingSubscriptionType,
    setCurrencyType,
    setPlanFromPathType,
    setLeavingToManagePlanType
} from "./actions"

const rootReducer = (state = {
    user: null,
    newSubscription: null,
    currentSubscription: null,
    plan: null,
}, action) => {
    switch (action.type) {        
        case setUserType:
            return {
                ...state,
                user: action.user
            }
        case selectNewSubscriptionType:
            return {
                ...state,
                newSubscription: action.subscription
            }        
        case setExistingSubscriptionType:
            return {
                ...state,
                currentSubscription: action.subscription
            }   
        case setCurrencyType: 
            return {
                ...state,
                currency: action.currency
            }    
        case setPlanFromPathType:
            return {
                ...state,
                planFromPath: action.planFromPath
            } 
        case setLeavingToManagePlanType:
            return {
                ...state,
                leavingToManagePlan: action.leaving
            }
        default:
            break
    }
    return state
}

export default rootReducer