import React from 'react'
import { connect } from 'react-redux'
import { billingPlansOptions } from '../billingConfig'
import { DesktopPricingPlan } from './DesktopPricingPlan'
import { MobilePricingPlan } from './MobilePricingPlan'
import { selectNewSubscription } from '../../../store/actions';
import { getCurrency } from '../../../utilities/currency'

const descriptions = [
    'Invoices per month',
    'Zero-configuration parsing',
    'English language invoices',
    'Import from Gmail attachments',
    'Send to Google Sheets',
    'Line-items grid extraction',
    'Support'
]

export const PricingTable = ({ dispatch, onPlanSelected, currentPlan, currency }) => {
    const newPlanSelected = newSubscription => {
        dispatch(selectNewSubscription(newSubscription))
        onPlanSelected()
    }
    return (
        <React.Fragment>
            <div className='lg:hidden'>
                {billingPlansOptions.map((plan, i) => <MobilePricingPlan key={i} {...plan} selected={currentPlan.label === plan.label} descriptions={descriptions} onClick={() => newPlanSelected(plan)} currencySymbol={getCurrency(currency)} />)}
            </div>
            <ul className="grid grid-cols-1 hidden lg:grid sm:grid-cols-2 lg:grid-cols-5">
                <li className="divide-y divide-gray-200 min-w-max">
                    <div className="px-6 align-top text-center">
                        <p className="text-pink mb-2 font-medium">{'\u00A0'}</p>
                        <p>
                            <span className="text-2xl font-extrabold text-purple">{'\u00A0'}</span>
                        </p>
                        {descriptions.map(description => (
                            <div key={description} className="py-5 px-6 text-sm font-normal text-left">
                                {description}
                            </div>
                        ))}                        
                    </div>
                </li>
                {billingPlansOptions.map((plan, i) => <DesktopPricingPlan key={i} {...plan} selected={currentPlan.label === plan.label} isLast={i === billingPlansOptions.length - 1} onClick={() => newPlanSelected(plan)} currencySymbol={getCurrency(currency)} />)}
            </ul>
        </React.Fragment >
    )
}


export const mapStateToProps = (state, ownProps) => {
    return {
        newSubscription: state.newSubscription,
        currency: state.currency,
        currentPlan: ownProps.currentPlan,
        onPlanSelected: ownProps.onPlanSelected
    }
}

export const PricingTableConnected = connect(mapStateToProps)(PricingTable)