import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Error as ErrorReactElement } from '../../components/Error'
import { URLS } from '../../config'
import { selectNewSubscription, setExistingSubscription, setPlanFromPath, setLeavingToManagePlan } from '../../store/actions'
import { getCurrency } from '../../utilities/currency'
import { makeRequest } from '../../utilities/endpoints'
import { billingPlansOptions } from './billingConfig'
import { CurrentPlanConnected } from './components/CurrentPlan'
import { PricingTableConnected } from './components/PricingTable'
import { SuccessNotification } from './components/SuccessNotification'
import * as Sentry from "@sentry/react";
import { Loader, LOADER_SIZES } from '../../components/Loader'

export const Billing = ({ reloadToUpdate, dispatch, leavingToManagePlan, currentSubscription, user, currency }) => {
    const history = useHistory()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const planFromPath = urlParams && urlParams.get('plan')
    const [hasPlanError, setHasPlanError] = useState(false)
    const [purchasedPlansRequestSent, setPurchasedPlansRequestSent] = useState(false)
    const [customerPortalRequstSent, setCustomerPortalRequestSent] = useState(false)
    const [managePlanUrl, setManagePlanUrl] = useState(false)
    const [shouldReloadData, setShouldReloadData] = useState(reloadToUpdate || leavingToManagePlan)
    const [showLoader, setShowLoader] = useState(reloadToUpdate)
    const [showSuccessNotification, setShowSuccessNotification] = useState(reloadToUpdate)

	const hideNotification = () => {
        setShowSuccessNotification(false);
    }

    const onPlanSelected = () => history.push('/subscription')

    const currentSubscriptionPlan = currentSubscription && currentSubscription.planType

    const currentPlan = currentSubscriptionPlan ?
        billingPlansOptions.find(el => el.label.toUpperCase() === currentSubscriptionPlan)
        : billingPlansOptions[0]

    useEffect(() => {
        if (!user) {
            if (planFromPath) {
                dispatch(setPlanFromPath(planFromPath))
            }
            history.push('/auth')
        } else {
            if (planFromPath) {
                dispatch(setPlanFromPath(null))
                const foundPlan = billingPlansOptions.find(el => el.label.toLowerCase() === planFromPath.toLowerCase())
                if (!currentSubscriptionPlan) {
                    if (foundPlan) {
                        dispatch(selectNewSubscription(foundPlan))
                        history.push('/subscription')
                    }

                } else {
                    setHasPlanError(true)
                }
            }
            if (!purchasedPlansRequestSent || shouldReloadData) {
                setShouldReloadData(false)
                setPurchasedPlansRequestSent(true)
                dispatch(setLeavingToManagePlan(false))
                makeRequest.get(URLS.purchasedPlans)
                    .then(res => {
                        makeRequest.get(`${URLS.main}/${URLS.users}/me`)
                            .then(({ data: internalApiResult }) => {
                                if (internalApiResult && internalApiResult.licence) {
                                    const license = internalApiResult.licence && internalApiResult.licence.status !== 'CANCELED' ? internalApiResult.licence : null
                                    if (license && license.endTimestamp && license.endTimestamp < new Date().getTime()) {
                                        setShowLoader(false)
                                        dispatch(setExistingSubscription(null))
                                    } else {
                                        setShowLoader(false)
                                        dispatch(setExistingSubscription(license))
                                    }

                                } else {
                                    setShowLoader(false)
                                    dispatch(setExistingSubscription(null))
                                }
                            })
                            .catch(e => Sentry.captureException(e))
                    })
                    .catch(e => Sentry.captureException(e))
            }
            if (!customerPortalRequstSent) {
                setCustomerPortalRequestSent(true)
                makeRequest.get(`${URLS.customerPortal}?returnUrl=${window.location.href}`)
                    .then(res => {
                        setManagePlanUrl(res.data.stripeCustomerPortalUrl)
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status === 404) {
                            // do nothing. 404 means that the user simply doesn't have a subscription
                            setShowLoader(false)
                        } else {
                            Sentry.captureException(error)
                        }
                    })
            }
        }
    }, [user, purchasedPlansRequestSent, history, currentSubscription, customerPortalRequstSent, dispatch, planFromPath])

    return (
        <React.Fragment>
            <div className={`${showLoader ? 'opacity-0' : 'opacity-100'} transition duration-150 transition-opacity max-w-7xl mx-auto pt-6 lg:py-10 lg:px-4 lg:px-8 relative`}>
                {(showSuccessNotification) && (
                    <SuccessNotification 
                        hideNotification={hideNotification} 
                        explanation={`You can now parse up to ${currentPlan.perMonth} invoices a month.`} 
                    />
                )}
                {hasPlanError && (
                    <div className='my-4'>
                        <ErrorReactElement>
                        You already have an active plan on your account.
                        Please use Manage plan button to upgrade.
                        </ErrorReactElement>
                    </div>
                )}
                <div className="text-purple text-xl mb-5 px-4 lg:px-0">Your current plan</div>
                <CurrentPlanConnected {...currentPlan} currencySymbol={getCurrency(currency)} managePlanUrl={managePlanUrl} />            
                {!currentSubscriptionPlan && (
                    <React.Fragment>
                        <div className="text-purple text-xl mb-5 px-4 lg:px-0">Upgrade</div>
                        <div className="bg-white lg:shadow lg:rounded-md relative">
                            <div className="max-w-7xl mx-auto px-0 py-6 lg:px-8 lg:py-12">
                                <PricingTableConnected currentPlan={currentPlan} onPlanSelected={onPlanSelected} />                            
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            {showLoader && <div className="absolute flex items-center top-0 left-0 h-full w-full bg-gray-900 bg-opacity-10">
                <Loader className="m-auto" size={LOADER_SIZES.BIG} />    
            </div>}
        </React.Fragment>
    )
}

export const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        currency: state.currency,
        currentSubscription: state.currentSubscription,
        leavingToManagePlan: state.leavingToManagePlan,
        reloadToUpdate: ownProps.reloadToUpdate
    }
}

export const BillingConnected = connect(mapStateToProps)(Billing)