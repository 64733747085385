export const setUserType = 'SET_USER'
export const setUser = user => ({
    type: setUserType,
    user: user
})

export const selectNewSubscriptionType = 'SELECT_NEW_SUBSCRIPTION'
export const selectNewSubscription = subscription => ({
    type: selectNewSubscriptionType,
    subscription: subscription,
})

export const setExistingSubscriptionType = 'SET_EXISTING_SUBSCRIPTION'
export const setExistingSubscription = subscription => ({
    type: setExistingSubscriptionType,
    subscription: subscription,
})

export const setCurrencyType = 'SET_CURRENCY'
export const setCurrency = currency => ({
    type: setCurrencyType,
    currency: currency,
})

export const setPlanFromPathType = 'SET_PLAN_FROM_PATH'
export const setPlanFromPath = planFromPath => ({
    type: setPlanFromPathType,
    planFromPath: planFromPath,
})

export const setLeavingToManagePlanType = 'SET_LEAVING_TO_MANAGE_PLAN'
export const setLeavingToManagePlan = leaving => ({
    type: setLeavingToManagePlanType,
    leaving: leaving,
})