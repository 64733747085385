import React from 'react'

export const SuccessNotification = ({
    header = 'Thank you for your purchase!',
    explanation = '',
	hideNotification
}) => {
    return (
        <div className='flex flex-row bg-white rounded border border-grey w-full my-3 p-5'>
			<div className="my-auto ml-1 mr-6">
				<div className="w-min bg-green-light rounded-full p-2 mx-auto">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-dark" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
					</svg>
				</div>
			</div>
			<div>
				<div className='text-lg mb-1 text-black'>
					{header}
				</div>
				<div className='text-sm text-gray-600'>
					{explanation}
				</div>
			</div>
			<div onClick={hideNotification} className="my-auto ml-auto cursor-pointer">
				<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</div>
        </div>
    )
}
