import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Sentry from "@sentry/react";
import { useHistory } from 'react-router-dom'
import { URLS } from '../../config'
import { formatSum, getCurrency } from '../../utilities/currency'
import countries from './countries.json'
import {loadStripe} from '@stripe/stripe-js';
import { setCurrency } from '../../store/actions'
import stripeLogo from '../../assets/images/stripe.svg'
import { makeRequest } from '../../utilities/endpoints'
import { Loader } from '../../components/Loader'
import { Error } from '../../components/Error'

let stripe = null;

const getStripe = async () => {
    if (!stripe)
        stripe = await loadStripe(process.env.REACT_APP_STRIPE_ID);
    return stripe    
}

const PURCHASE_TYPE = {
    B2B: 'B2B',
    B2C: 'B2C'
}

export const Subscription = ({ dispatch, user, newSubscription }) => {
    const [country, setCountry] = useState()
    const [purchaseType, setPurchaseType] = useState()
    const [companyName, setCompanyName] = useState('')
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [addressState, setAddressState] = useState('')
    const [lastInfoRetrieved, setLastInfoRetrieved] = useState()
    const [paymentInfo, setPaymentInfo] = useState({
        taxRate: 0,
        currency: undefined,
    })
    const [vatError, setVatError] = useState(false)
    const [vatId, setVatId] = useState('')
    const [billingInfoRequested, setBillingInfoRequested] = useState(false)
    const [subscribeButtonClicked, setSubscribeButtonClicked] = useState(false)
    const [regularError, setRegularError] = useState(false)
    const [sentPurchaseInfoRequest, setSentPurchaseInfoRequest] = useState(false)
    const history = useHistory()

    const subscribe = () => {
        setSubscribeButtonClicked(true)
        const subscriptionData = {
            vatNumber: vatId,
            isB2BTransaction: purchaseType === PURCHASE_TYPE.B2B,
            companyName: companyName,
            customerName: name,
            address: {
                country: country,
                street: street,
                city: city,
                state: addressState,
                postalCode: postalCode
            },
            domain: user.email.split('@')[1],
            plans: [{
                stripePlanId: paymentInfo.currency === 'EUR' ? newSubscription.idInEuro : newSubscription.idInDollars,
                quantity: 1
            }],
            goBackUrl: window.location.href
        }

        makeRequest.post(`${URLS.stripeCheckoutSession}`, subscriptionData)
        .catch(error => {            
            setSubscribeButtonClicked(false)
            const errorMessage = error.response && error.response.data.error.message        
            const isVatError = errorMessage && errorMessage.includes('tax_id_invalid')
            if (isVatError) {
                setVatError(true)
            } else {
                setRegularError(true)
                Sentry.captureException(error)
            }
        })
        .then(async res => {
            if (res && res.data) {
                const { stripeCheckoutSessionId } = res.data
                const stripe = await getStripe()                        
                return stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId })
            }
        })
    }

    useEffect(() => {
        const getPurchaseInfo = () => {
            setSentPurchaseInfoRequest(true)
            makeRequest.get(`${URLS.paymentInfo}?country=${country}&type=${purchaseType}`)
            .then(res => {
                setPaymentInfo(res.data)
                dispatch(setCurrency(res.data.currency.toUpperCase()))
                setSentPurchaseInfoRequest(false)
            })
            .catch(error => {
                setSentPurchaseInfoRequest(false)
                Sentry.captureException(error)
            })
        }

        if (!newSubscription) {
            history.push('/billing')
        } else {
            if (!user) {
                history.push('/auth')
            } else {
                if (!billingInfoRequested) {
                    setBillingInfoRequested(true)
                    makeRequest.get(URLS.userBilling)
                    .then(res => {                        
                        console.log('billingInfo', res.data)
                        setCompanyName(res.data.companyName)
                        setPaymentInfo({
                            currency: res.data.currency.toUpperCase()
                        })
                        setName(res.data.customerName)
                        setCity(res.data.address.city)
                        setCountry(res.data.address.country)
                        setPostalCode(res.data.address.postalCode)
                        setAddressState(res.data.address.state)
                        setStreet(res.data.address.street)
                        setVatId(res.data.vatNumber)
                        dispatch(setCurrency(res.data.currency.toUpperCase()))
                        if (res.data.vatNumber) {
                            setPurchaseType(PURCHASE_TYPE.B2B)
                        } else {
                            setPurchaseType(PURCHASE_TYPE.B2C)
                        }
                    })
                    .catch(error => Sentry.captureException(error))
                }
                if (country && purchaseType && lastInfoRetrieved !== country + purchaseType) {
                    setLastInfoRetrieved(country + purchaseType)
                    getPurchaseInfo()
                }
            }
        }        
    }, [user, newSubscription, country, purchaseType, billingInfoRequested, dispatch, history, lastInfoRetrieved])


    const onCountrySelected = e => {
        setCountry(e.target.value)
    }

    const goBackToBilling = () => history.push('/billing')    

    const isSubscribeButtonEnabled = purchaseType && purchaseType === PURCHASE_TYPE.B2B ? 
        country && vatId && companyName && name && country && street && city
        :
        country && name && country && city && street
    const currency = getCurrency(paymentInfo && paymentInfo.currency)
    const taxes = parseFloat(paymentInfo.taxRate / 100 * newSubscription.pricing)

    return (
        <div className="max-w-7xl mx-auto pt-6 lg:py-10 lg:px-8">
            {regularError && (
            <div className='my-3'>
                <Error>
                    We are sorry but there was an error when processing your subscription request.
                    <br/>
                    Please contact <a href="mailto:support@invoicetosheet.com">support@invoicetosheet.com</a> for further assistance.
                </Error>
            </div>)}
            <div className="text-purple text-xl mb-5 px-4" onClick={goBackToBilling}>
                <button>
                    <svg className="h-5 w-5 text-purple inline-block mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M8.53033 1.53033C8.82322 1.23744 8.82322 0.762563 8.53033 0.46967C8.23744 0.176777 7.76256 0.176777 7.46967 0.46967L0.46967 7.46967C0.397763 7.54158 0.343509 7.62445 0.306909 7.71291C0.270239 7.80134 0.25 7.89831 0.25 8C0.25 8.10169 0.270239 8.19866 0.306909 8.28709C0.343239 8.37489 0.396963 8.4572 0.468082 8.52874C0.46861 8.52927 0.46914 8.5298 0.46967 8.53033M0.470043 8.5307L7.46967 15.5303C7.76256 15.8232 8.23744 15.8232 8.53033 15.5303C8.82322 15.2374 8.82322 14.7626 8.53033 14.4697L2.81066 8.75H15C15.4142 8.75 15.75 8.41421 15.75 8C15.75 7.58579 15.4142 7.25 15 7.25H2.81066L8.53033 1.53033"
                            fill="currentColor" />
                    </svg>
                </button>
                Billing information
            </div>

            <div className="grid grid-cols-5 gap-6">
                <div className="bg-white lg:rounded-md lg:shadow col-start-1 col-span-5 py-6 lg:py-8 px-6 lg:px-8 sm:col-span-3">
                    <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                                <div className="pb-5">
                                    <label htmlFor="country" className="block font-semibold text-purple pb-1 text-sm">
                                        Country
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            id="country"
                                            name="country"
                                            value={country}
                                            autoComplete="country"
                                            defaultValue=""
                                            className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter"
                                            onChange={onCountrySelected}
                                        >
                                            <option value="" disabled>Select country</option>
                                            {countries.map(country => (
                                                <option key={country.country} value={country.abbreviation}>{country.country}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="pb-5 grid gap-y-6 gap-x-4 grid-cols-2">
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block font-semibold text-purple pb-1 text-sm">
                                            Is this a business purchase?
                                        </label>
                                        <div className="mt-3 flex space-x-6">
                                            <div className="flex items-center">
                                                <input id="b2b" checked={purchaseType === PURCHASE_TYPE.B2B} name="business" type="radio" onChange={() => setPurchaseType(PURCHASE_TYPE.B2B)}
                                                    className="focus:ring-purple-lighter h-4 w-4 text-pink border-gray-300" />
                                                <label htmlFor="b2b"
                                                    className="ml-3 block text-sm text-gray-700" >
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input id="b2c" checked={purchaseType === PURCHASE_TYPE.B2C} name="business" type="radio" onChange={() => setPurchaseType(PURCHASE_TYPE.B2C)}
                                                    className="focus:ring-purple-lighter h-4 w-4 text-pink border-gray-300" />
                                                <label htmlFor="b2c"
                                                    className="ml-3 block text-sm text-gray-700">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {purchaseType === PURCHASE_TYPE.B2B && (
                                        <div className="col-span-2 col-start-1 sm:col-span-1 sm:col-start-2">
                                            <label htmlFor="vat" className="block font-semibold text-purple pb-1 text-sm">
                                                VAT ID
                                            </label>
                                            <div className="relative mt-1 z-0">
                                                <input type="text" name="vat" id="vat" className={
                                                    vatError ? "shadow-sm block w-full sm:text-sm border-red-300 text-red-900 rounded-md focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter"
                                                    : "shadow-sm block w-full sm:text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter"
                                                 } value={vatId} onChange={e => setVatId(e.target.value)} />
                                                {vatError && (
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                )}
                                            </div>
                                            {vatError && (
                                                <p className="mt-2 text-sm text-red-600" id="email-error">This VAT ID doesn't seem to be valid.</p>
                                            )}
                                        </div>
                                    )}
                                    
                                </div>
                                
                                {purchaseType === PURCHASE_TYPE.B2B && (
                                    <div className="pb-5">
                                        <label htmlFor="company" className="block font-semibold text-purple pb-1 text-sm">Company name</label>
                                        <div className="mt-1">
                                            <input value={companyName} onChange={e => setCompanyName(e.target.value)} type="text" name="company" id="company" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                        </div>
                                    </div>
                                )}

                                <div className="pb-5">
                                    <label htmlFor="name" className="block font-semibold text-purple pb-1 text-sm">Name</label>
                                    <div className="mt-1">
                                        <input value={name} onChange={e => setName(e.target.value)} type="text" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                    </div>
                                </div>

                                <div className="pb-5">
                                    <label htmlFor="address" className="block font-semibold text-purple pb-1 text-sm">Street address</label>
                                    <div className="mt-1">
                                        <input value={street} onChange={e => setStreet(e.target.value)} type="text" id="address" name="address" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                    </div>
                                </div>

                                <div className="pb-5 grid gap-y-6 gap-x-4 grid-cols-2">
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="city" className="block font-semibold text-purple pb-1 text-sm">
                                            City
                                        </label>
                                        <div className="mt-1">
                                            <input value={city} onChange={e => setCity(e.target.value)} type="text" name="city" id="city" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                        </div>
                                    </div>

                                    <div className="col-span-2 col-start-1 sm:col-span-1 sm:col-start-2">
                                        <label htmlFor="state" className="block font-semibold text-purple pb-1 text-sm">
                                            State / Province <span className="text-gray-400 font-normal">(optional)</span>
                                        </label>
                                        <div className="mt-1">
                                            <input value={addressState} onChange={e => setAddressState(e.target.value)} type="text" name="state" id="state" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="postal_code" className="block font-semibold text-purple pb-1 text-sm">Postal code <span className="text-gray-400 font-normal">(optional)</span></label>
                                    <div className="mt-1">
                                        <input value={postalCode} onChange={e => setPostalCode(e.target.value)} type="text" name="postal_code" id="postal_code" className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:border-purple focus:ring-2 focus:ring-offset-2 focus:ring-purple-lighter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                {/* <!-- RECAP --> */}
                <div className="col-start-1 col-span-5 sm:col-span-2 sm:col-start-4 text-purple">
                    <div className="bg-white lg:rounded-md lg:shadow py-6 lg:py-8 px-6 lg:px-8">
                        <div className="border-b border-gray-200 pb-4 mb-4">
                            <p className="text-md pb-2">{newSubscription.label} <span className="font-normal text-gray-400">plan</span></p>
                            <p className="text-md pb-2">{newSubscription.perMonth} <span className="font-normal text-gray-400">invoices / month</span></p>
                            <p className="text-md">1 <span className="font-normal text-gray-400">user</span></p>
                        </div>
                        <div className="flex pb-2 text-gray-600">
                            <p>Total without taxes</p><p className="text-right ml-auto">{sentPurchaseInfoRequest ? '-' : formatSum(currency, newSubscription.pricing)}</p>
                        </div>
                        <div className="flex pb-2 text-gray-600">
                            <p>Taxes ({paymentInfo.taxRate}%)</p><p className="text-right ml-auto">{sentPurchaseInfoRequest ? '-' : formatSum(currency, taxes)}</p>
                        </div>
                        <div className="flex pb-2 font-semibold text-lg text-purple">
                            <p>Total amount</p><p className="text-right ml-auto">{sentPurchaseInfoRequest ? '-' : formatSum(currency, newSubscription.pricing * 1 + taxes)}</p>
                        </div>
                        <p className="text-right text-sm text-gray-400">Billed monthly</p>

                        <button
                            disabled={!isSubscribeButtonEnabled || subscribeButtonClicked}
                            onClick={subscribe}
                            className="flex justify-center items-center disabled:opacity-50 block mt-6 w-full bg-pink rounded-md px-4 py-2 font-semibold text-white text-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-light">
                                {subscribeButtonClicked ? <Loader /> : <span>Subscribe</span>}                                
                            </button>
                    </div>
                    <img className="mx-auto my-6" src={stripeLogo} alt='stripe logo' />
                </div>
            </div>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
        newSubscription: state.newSubscription || {}
    }
}

export const SubscriptionConnected = connect(mapStateToProps)(Subscription)