import React from 'react'
import { connect } from 'react-redux'
import { setLeavingToManagePlan } from '../../../store/actions'
import { formatSum } from '../../../utilities/currency'

export const CurrentPlan = ({ label, perMonth, pricing, pricingPeriod, managePlanUrl, currencySymbol, dispatch }) => {
    const managePlan = () => {
        dispatch(setLeavingToManagePlan(true))
        window.location.href = managePlanUrl
    }
    return (
        <div className="bg-white lg:shadow overflow-hidden lg:rounded-md mb-10">
            <ul className="divide-y divide-gray-200">
                <li className="p-2">
                    <div className="px-4 py-4 lg:px-6">
                        <div className="lg:flex items-center justify-between">
                            <p className="text-pink font-medium truncate flex-1 pb-2 lg:pb-0">
                                {label}
                                </p>
                            <p className="text-gray-600 text-sm truncate flex-1 pb-2 lg:pb-0">
                                Up to {perMonth} invoices <span className="text-gray-400">{pricingPeriod}</span>
                            </p>
                            <div className="flex-1 lg:flex items-center">
                                <p className="text-gray-600 text-sm truncate flex-1 pb-2 lg:pb-0">
                                    {pricing && (
                                        <React.Fragment>
                                            <strong>{formatSum(currencySymbol, pricing)}</strong> <span className="text-gray-400">{pricingPeriod}</span>
                                        </React.Fragment>
                                    )}
                                </p>
                                <button
                                    onClick={managePlan}
                                    disabled={!managePlanUrl || !pricing} type="button" className="disabled:opacity-50 inline-flex ml-auto text-sm items-center px-4 py-2 border border-pink font-medium rounded-md shadow-sm text-pink bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-light">
                                    Manage plan
                                    </button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}


export const mapStateToProps = (state, ownProps) => {
    return {
        currency: state.currency,
        ...ownProps
    }
}

export const CurrentPlanConnected = connect(mapStateToProps)(CurrentPlan)