import axios from "axios"
import { getGapiAuthInstance } from "./gapi"

const getAccessToken = () => {
    return new Promise((resolve) => {
        getGapiAuthInstance().then(auth => {
            const user = auth.currentUser.get()
            const { access_token, expires_at } = user.getAuthResponse(true) || {}
            if (!access_token || expires_at < new Date().getTime()) {
                user.reloadAuthResponse().then(res => {
                    resolve(res.access_token)
                })                 
            } else {
                resolve(access_token)
            }
        })
    })
}

export const makeRequest = {
    get: async url => {
        const token = await getAccessToken()
        return axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    },
    post: async (url, data) => {
        const token = await getAccessToken()
        return axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }    
}