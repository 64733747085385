import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import { createStore } from 'redux'
import reportWebVitals from './reportWebVitals';
import Routing from './Routing';
import rootReducer from './store/reducer';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import dotenv from 'dotenv'
dotenv.config()

const persistConfig = {
  key: 'root',
  storage,
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, sentryReduxEnhancer)

Sentry.init({
  dsn: "https://50de52f501fb45d890ff1734895b8de3@o558010.ingest.sentry.io/5699291",
  environment: process.env.REACT_APP_ENVIRONMENT
});

Sentry.init({ dsn: "https://50de52f501fb45d890ff1734895b8de3@o558010.ingest.sentry.io/5699291" });

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Routing store={store} persistor={persistStore(store)} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

