import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export const HeaderLink = ({ mobile, to, children }) => {
    const { pathname } = useLocation();
    if (mobile) {
        return <Link to={to} className={pathname === to ? 'bg-purple-dark block text-white px-3 py-2 rounded-md font-medium' : 'block text-white px-3 py-2 rounded-md font-medium'}>{children}</Link>
    }
    return (                                                                
        <Link to={to} className={`${pathname === to ? 'bg-purple-dark' : ''} text-white px-3 py-2 rounded-md text-sm font-medium`}>{children}</Link>                                
    )
}
