import React from 'react'
import { formatSum } from '../../../utilities/currency'

export const MobilePricingPlan = ({ descriptions, label, pricing, pricingPeriod, perMonth, checkboxes, support, onClick, selected, currencySymbol }) => (
    <div className="px-4 pt-5">

        <div className="px-6 mt-12">
            <h2 className="text-lg leading-6 font-medium text-pink">{label}</h2>
            <p className="mt-2">
                <span className="text-2xl font-extrabold">{pricing ? formatSum(currencySymbol, pricing) : ''}</span>
                <span className="text-base font-medium text-gray-400">{pricingPeriod}</span>
            </p>
        </div>

        <table className="mt-4 w-full text-sm">
            <tbody>
                <tr>
                    <th className="py-3 pl-6 font-normal text-gray-600 text-left" scope="row">
                        {descriptions[0]}
                    </th>
                    <td className="py-3 pr-6 text-right whitespace-nowrap">
                        <span className="text-gray-400">Up to</span> {perMonth}
                </td>
                </tr>
                {Object.values(checkboxes).map((checkbox, i) => (
                    <tr key={i}>
                        <th className="py-3 pl-6 font-normal text-gray-600 text-left" scope="row">{descriptions[i + 1]}</th>
                        <td className="py-3 pr-6 text-right">
                            {checkbox ? (
                                <svg className="ml-auto h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                    fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd" />
                                </svg>
                            ) : (
                                    <svg className="ml-auto h-5 w-5 text-gray-400 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                                    </svg>
                                )}
                        </td>
                    </tr>
                ))}                
                <tr>
                    <th className="py-3 pl-6 font-normal text-gray-600 text-left" scope="row">Support</th>
                    <td className="py-3 pr-6 whitespace-nowrap text-right">
                        {support}
                </td>
                </tr>
            </tbody>
        </table>

        <div className="px-4 pt-5">
            {!!pricing && (
                <button
                    onClick={onClick}
                    disabled={selected}
                    className="block w-full bg-pink rounded-md px-4 py-2 font-semibold text-white text-center border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-light disabled:opacity-50">Subscribe</button>
            )}
        </div>

    </div>
)
