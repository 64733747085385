export const billingPlansOptions = [
    {
        label: 'Free',
        perMonth: 5,
        checkboxes: {
            parsing: true,
            engLangInvoices: true,
            gmailImportedAttachments: true,
            storingInSheets: true,
            lineItemsGridExtraction: false,
        },
        support: 'Best effort support',
    },
    {
        label: 'Starter',
        pricing: '29',
        pricingPeriod: '/mo',
        perMonth: 100,
        checkboxes: {
            parsing: true,
            engLangInvoices: true,
            gmailImportedAttachments: true,
            storingInSheets: true,
            lineItemsGridExtraction: true,
        },
        support: 'Priority support',
        idInEuro: process.env.REACT_APP_START_PLAN_ID_EURO,
        idInDollars: process.env.REACT_APP_START_PLAN_ID_DOLLARS
    },
    {
        label: 'Pro',
        pricing: '59',
        pricingPeriod: '/mo',
        perMonth: 250,
        checkboxes: {
            parsing: true,
            engLangInvoices: true,
            gmailImportedAttachments: true,
            storingInSheets: true,
            lineItemsGridExtraction: true,
        },
        support: 'Priority support',
        idInEuro: process.env.REACT_APP_PRO_PLAN_ID_EURO,
        idInDollars: process.env.REACT_APP_PRO_PLAN_ID_DOLLARS,
    },
    {
        label: 'Business',
        pricing: '199',
        pricingPeriod: '/mo',
        perMonth: 1000,
        checkboxes: {
            parsing: true,
            engLangInvoices: true,
            gmailImportedAttachments: true,
            storingInSheets: true,
            lineItemsGridExtraction: true,
        },
        support: 'Priority support',
        idInEuro: process.env.REACT_APP_BUSINESS_PLAN_ID_EURO,
        idInDollars: process.env.REACT_APP_BUSINESS_PLAN_ID_DOLLARS,
    }
]