let auth2 = null

export const getGapiAuthInstance = () => {
    return new Promise(resolve => {
        if (auth2) {
            resolve(auth2)
        } else {
            const createToken = () => window.gapi.load('auth2', () => {
                auth2 = window.gapi.auth2.init({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    scope: 'profile'
                })
                resolve(auth2)
            })
            if (window.gapi) {
                createToken()
            } else {
                let intervalId = setInterval(() => {
                    if (window.gapi) {
                        createToken()
                        clearInterval(intervalId)
                    }
                }, 100)
            }
        }
    })
}