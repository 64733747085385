import React, { useRef, useState } from 'react'
import { connect } from 'react-redux';
import logoLight from '../assets/images/logoLight.svg'
import { useOutsideClickHandler } from '../customHooks/useOutsideClickHandler';
import { selectNewSubscription, setExistingSubscription, setUser } from '../store/actions';
import { HeaderLink } from './HeaderLink';
import { getGapiAuthInstance } from '../utilities/gapi';

const navigationLinks = [
    // {
    //     to: '/billing',
    //     label: 'Billing'
    // },
    // {
    //     to: '/auth',
    //     label: 'Auth'
    // }
    // used for debugging purposes
]

export const Header = ({ dispatch, user }) => {
    const userProfileRef = useRef(null);
    const mobileMenuRef = useRef(null);

    const [mobileNavShown, setMobileNavShown] = useState(false)
    const [userProfileClicked, setUserProfileClicked] = useState(false)

    const toggleMobileNav = () => setMobileNavShown(!mobileNavShown)
    const toggleUserProfileClicked = () => setUserProfileClicked(!userProfileClicked)

    useOutsideClickHandler(userProfileRef, () => setUserProfileClicked(false));
    useOutsideClickHandler(mobileMenuRef, () => setMobileNavShown(false));

    const signOut = () => {
        setUserProfileClicked(false)
        getGapiAuthInstance().then(auth => {
            auth.signOut()
            localStorage.clear()
            dispatch(setUser(null))
            dispatch(selectNewSubscription(null))
            dispatch(setExistingSubscription(null))
            setMobileNavShown(false)
        })
    }

    return (
        <nav className="bg-purple fixed w-full top-0 z-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <a href="/billing"><img className="h-8 w-auto" src={logoLight} alt="Invoice to Sheet" /></a>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {navigationLinks.map(link => (
                                    <HeaderLink key={link.to} to={link.to}>{link.label}</HeaderLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6" ref={userProfileRef} onClick={toggleUserProfileClicked}>
                            <div className="ml-3 relative">
                                <div>
                                    <button
                                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        id="user" aria-haspopup="true">
                                        {user && <img className="h-8 w-8 rounded-full"
                                            src={user.photoURL}
                                            alt="" />
                                        }
                                    </button>
                                </div>

                                <div className={`${userProfileClicked ? '' : 'hidden'} origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5`}
                                    role="menu" aria-orientation="vertical" aria-labelledby="user-menu" id="user-menu">
                                    <div className="block px-4 py-2 text-sm cursor-default">
                                        {user && (
                                            <div className='flex items-center'>
                                                <div className='w-8'>
                                                    <img className="h-8 w-8 rounded-full"
                                                        src={user.photoURL}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className='ml-3'>
                                                    <div className='mb-1 text-base'>
                                                        {user.name}
                                                    </div>
                                                    <div className='text-dark-gray'>
                                                        {user.email}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <hr />
                                    <div className="block px-4 py-2 text-sm cursor-pointer"
                                        role="menuitem" onClick={signOut}>Sign out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden" onClick={toggleMobileNav}>
                        <button id="menu-trigger"
                            className="bg-purple-dark inline-flex items-center justify-center p-2 rounded-md text-white hover:text-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className={`md:hidden ${mobileNavShown ? '' : 'hidden'}`} id="menu" ref={mobileMenuRef}>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {navigationLinks.map(link => (
                        <HeaderLink key={link.to} mobile to={link.to}>{link.label}</HeaderLink>
                    ))}
                </div>
                {user && (
                    <div className="pt-4 pb-3 border-t border-purple-dark">
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <div className='flex items-center text-gray-400'>
                                    <div className='w-10'>
                                        <img className="h-10 w-10 rounded-full"
                                            src={user.photoURL}
                                            alt=""
                                        />
                                    </div>
                                    <div className='ml-3'>
                                        <div className='mb-1 text-base'>
                                            {user.name}
                                        </div>
                                        <div className=''>
                                            {user.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                            <div
                                onClick={signOut}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400"
                            >
                                Sign out
                        </div>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const HeaderConnected = connect(mapStateToProps)(Header)