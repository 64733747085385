import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Error } from '../../components/Error'
import googleLogo from '../../assets/images/google.svg'
import logoMinDart from '../../assets/images/logoMinDark.svg'
import { URLS } from '../../config'
import { setExistingSubscription, setUser } from '../../store/actions';
import { connect } from 'react-redux';
import { getGapiAuthInstance } from '../../utilities/gapi';
import { makeRequest } from '../../utilities/endpoints';
import { Loader } from '../../components/Loader'

const BUTTONS = {
    SIGN_IN: 'SIGN_IN',
    SIGN_UP: 'SIGN_UP',
}

export const Auth = ({ dispatch, user, planFromPath }) => {
    const history = useHistory()
    const [signInError, setSignInError] = useState()
    const [signUpError, setSignUpError] = useState()
    const [buttonClicked, setButtonClicked] = useState()
    const defaultError = 'We are sorry, but an error occured. Please try again later.'

    useEffect(() => {
        if (user && user.email) {
            if (planFromPath) {                
                history.push(`/billing?plan=${planFromPath}`)
            } else {
                history.push('/billing')
            }
        }
    }, [user, history])

    const signIn = () => {
        if (!!buttonClicked)
            return
        getGapiAuthInstance().then(auth => {
            auth.signIn()
            .then(user => {
                    setButtonClicked(BUTTONS.SIGN_IN)
                    const { access_token, expires_at } = user.getAuthResponse(true)
                    const basicProfile = user.getBasicProfile()
                    makeRequest.get(`${URLS.main}/${URLS.users}/me`)
                    .then(({ data: internalApiResult }) => {
                        dispatch(setUser({
                            email: basicProfile.getEmail(),
                            photoURL: basicProfile.getImageUrl(),
                            name: basicProfile.getName() || internalApiResult.name,
                            credential: {
                                accessToken: access_token,
                                expiresAt: expires_at
                            }
                        }))
                        if (internalApiResult && internalApiResult.licence) {
                            dispatch(setExistingSubscription(internalApiResult.licence))
                        }
                    }).catch(() => {
                        setButtonClicked(null)
                        setSignInError(`There is no Invoice to Sheet user associated with ${basicProfile.getEmail()} account.`)
                    })
                })
        })
    }

    const signUp = () => {
        if (!!buttonClicked)
            return
        getGapiAuthInstance().then(auth => {
            auth.signIn()
            .then(user => {
                setButtonClicked(BUTTONS.SIGN_UP)
                const { access_token, expires_at } = user.getAuthResponse(true)
                const basicProfile = user.getBasicProfile()
                makeRequest.post(`${URLS.main}/${URLS.users}`)
                .then(({ data: internalApiResult }) => {
                    dispatch(setUser({
                        email: basicProfile.getEmail(),
                        photoURL: basicProfile.getImageUrl(),
                        name: basicProfile.getName() || internalApiResult.name,
                        credential: {
                            accessToken: access_token,
                            expiresAt: expires_at
                        }
                    }))
                }).catch(() => {
                    setButtonClicked(null)
                    setSignUpError(defaultError)
                })
            }).catch((error) => {
                setButtonClicked(null)
                setSignUpError(error)
            })
        })
    }

    return (
        <div className="min-h-screen bg-purple flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white pt-14 pb-10 px-4 shadow sm:rounded-lg sm:px-10">
                    <img className="mx-auto h-24 w-auto" src={logoMinDart} alt="Workflow" />
                    <h1 className="font-title text-purple text-center text-2xl mt-6">Welcome to Invoice to Sheet</h1>
                    <p className="text-center text-purple-light mt-3">Please sign in with your Google account to access to your dashboard.</p>
                    {signInError && <Error>{signInError}</Error>}
                    <form className="py-8 space-y-6 flex justify-center" action="#" method="POST">
                        <div className="bg-blue-google text-white text-sm rounded flex p-0.5 items-center">
                            <div className="bg-white rounded-sm p-2.5">
                                {buttonClicked === BUTTONS.SIGN_IN ?
                                (
                                     <Loader />
                                )   : (
                                    <img src={googleLogo} width="18" height="18" alt='Google Logo' />
                                )}
                            </div>
                            <div className={`px-10 cursor-pointer ${!buttonClicked ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={signIn} >
                                Sign in with Google
                            </div>
                        </div>
                    </form>

                </div>
                <div className="bg-white pt-9 pb-5 px-4 shadow sm:rounded-lg sm:px-10 mt-5">
                    <p className="text-center text-purple-light">Don't have an account?</p>
                    {signUpError && <Error>{signUpError}</Error>}
                    <form className="py-4 space-y-6 flex justify-center" action="#" method="POST">
                        <div className="bg-white text-white text-sm rounded flex p-0.5 items-center border border-purple-light">
                            <div className="bg-white rounded-sm p-2.5">
                            {buttonClicked === BUTTONS.SIGN_UP ?
                                (
                                     <Loader />
                                )   : (
                                    <img src={googleLogo} width="18" height="18" alt='Google Logo' />
                                )}
                            </div>
                            <div className={`px-10 text-purple ${!buttonClicked ? 'cursor-pointer' : 'cursor-not-allowed'}`} onClick={signUp}>
                                Sign up with Google
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export const mapStateToProps = (state) => {
    return {
        user: state.user,
        planFromPath: state.planFromPath
    }
}

export const AuthConnected = connect(mapStateToProps)(Auth)