import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { HeaderConnected } from "./components/Header";
import { AuthConnected } from "./views/auth/Auth";
import { BillingConnected } from "./views/billing/Billing";
import { SubscriptionConnected } from "./views/subscription-form/Subscription";

export default function Routing({ store, persistor }) {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <HeaderConnected />
                    <main>
                        <Switch>
                            <Route path="/auth">
                                <AuthConnected />
                            </Route>
                            <Route path="/billing">
                                <BillingConnected />
                            </Route>
                            <Route path='/subscription'>
                                <SubscriptionConnected />
                            </Route>
                            <Route path='/paymentsuccess'>
                                {/* Callback for the stripe api */}
                                <BillingConnected reloadToUpdate />
                            </Route>
                            <Route path="/">
                                <BillingConnected />
                            </Route>
                        </Switch>
                    </main>
                </Router>
            </PersistGate>
        </Provider>
    );
}